import React from "react";

import { getRPC, methods } from "@ravenrebels/ravencoin-rpc";
const rpc = getRPC("anon", "anon", "https://rvn-rpc-mainnet.ting.finance/rpc");
export default function App() {
  const [status, setStatus] = React.useState("");
  const blocks = useBlocks();

  const messages: any = [];

  React.useEffect(() => {
    //Listen to "fetching blocks" events, needed to update status
    document.body.addEventListener("operation-fetching-block", (event: any) => {
      if (event.detail.blockNumber === 0) {
        setStatus("");
      } else {
        setStatus(
          "Fetching block " + event.detail.blockNumber.toLocaleString()
        );
      }
    });
  }, []);

  //One block contains many transactions, hence many messages
  blocks.map((b) => {
    b.tx.map((t) => {
      t.vout.map((vout) => {
        if (vout.scriptPubKey.asset?.message) {
          const obj = {
            transaction: t,
            vout,
            block: b,
          };
          messages.push(obj);
        }
      });
    });
  });

  return (
    <article>
      <h1>Ravencoin message wall</h1>
      <code>Alpha version 0.00001</code>
      <p>
        When transfering assets on Ravencoin you can add a memo/message. Here we
        list messages from the last 100 blocks.
      </p>
      Last block on Ravencoin:{" "}
      {blocks.length > 0 && blocks[0].height.toLocaleString()}
      {!!status && <div aria-busy={true}>{status}</div>}
      <Messages messages={messages} />
    </article>
  );
}
const blocksCached = {};

function Messages({ messages }) {
  if (messages.length === 0) {
    return (
      <article>
        <h2>No messages</h2>
      </article>
    );
  }
  return (
    <div>
      <h2>Messages</h2>

      {messages.map((m: any) => {
        const contentLink =
          "https://cloudflare-ipfs.com/ipfs/" +
          m.vout.scriptPubKey.asset.message;

        const transactionLink =
          "https://rvn.cryptoscope.io/tx/?txid=" + m.transaction.hash;
        return (
          <article>
            <table>
              <tbody>
                <tr key={m.transaction.hash}>
                  <td style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    <label>
                      Message{" "}
                      <a target="_blank" href={contentLink}>
                        Link
                      </a>
                      <input
                        type="text"
                        value={m.vout.scriptPubKey.asset.message}
                      />
                      <img
                        style={{ maxWidth: "200px" }}
                        src={contentLink}
                        onError={(
                          event: React.SyntheticEvent<HTMLImageElement, Event>
                        ) => {
                          const imgElement =
                            event.currentTarget as HTMLImageElement;
                          imgElement.style.display = "none";
                        }}
                      />
                    </label>

                    <label>
                      Asset sent{" "}
                      <input
                        type="text"
                        value={m.vout.scriptPubKey.asset.name}
                      ></input>
                    </label>
                    <label>
                      Block height
                      <input
                        type="text"
                        value={m.block.height.toLocaleString()}
                      />
                    </label>
                    <label>
                      Transaction id{" "}
                      <a href={transactionLink} target="_blank">
                        Link
                      </a>
                      <input type="text" value={m.transaction.hash} />
                    </label>
                  </td>
                </tr>{" "}
              </tbody>
            </table>
          </article>
        );
      })}
    </div>
  );
}
function useBlocks() {
  const [blocks, setBlocks] = React.useState<any>([]);

  React.useEffect(() => {
    get100Blocks(setBlocks);

    const interval = setInterval(() => {
      get100Blocks(setBlocks);
    }, 30 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return blocks;
}
async function get100Blocks(setBlocks) {
  const result: any = [];
  let hash = await rpc(methods.getbestblockhash, []);
  for (let i = 0; i < 100; i++) {
    const block: any = await getBlockByHash(hash);
    sendEvent(block.height - 1);
    result.push(block);
    hash = block.previousblockhash;
    setBlocks(result.concat([]));
  }
  sendEvent(0);
  setBlocks(result);
}

async function getBlockByHash(hash) {
  //Check cache
  if (!blocksCached[hash]) {
    const b = await rpc(methods.getblock, [hash, 2]);
    blocksCached[hash] = b;
  }
  return blocksCached[hash];
}

function sendEvent(blockNumber: number) {
  // Create a new custom event with a specified name and optional data
  const customEvent = new CustomEvent("operation-fetching-block", {
    detail: { blockNumber }, // You can provide additional data as needed
  });

  // Dispatch the custom event on a DOM element
  const targetElement = document.body;
  targetElement.dispatchEvent(customEvent);
}
